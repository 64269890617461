<template>
  <div class="community-wrapper">
    <div class="wrapper-header">
      <div class="logo-box">
        <img :src="communityInfo.logo" v-if="communityInfo.logo" />
      </div>
      <div class="guild-info">
        <div class="guild-name">
          <div class="name">{{ communityInfo.name }}</div>
          <div class="set-box" v-if="communityInfo.memberType && !this.sourceType" @click="$gbUtils.handleToPage('/setCommunity')">
            <i class="iconfont icon-a-set"></i>
            <span class="set-title">{{communityText.set}}</span>
          </div>
        </div>
        <div class="guild-text">
          <span> ID: {{ communityInfo.communityNo }}</span>
          <span class="mebmber-item">{{ communityText.member }}: {{ communityInfo.memberCount }}</span>
        </div>
        <!-- 社區持倉 -->
        <div class="guild-text">
          <span>{{communityText.communityPositions}}：</span>
          <span>{{$gbUtils.formatNumber(communityInfo.mebHoldTotal || 0)}}MEB</span>
        </div>
          <!-- meb质押量 -->
          <div class="guild-text">
            <span>MEB{{common.pledgeAmount}}：</span>
            <span>{{$gbUtils.formatNumber(communityInfo.mebLp || 0)}}MEB</span>
          </div>
        <!-- 质押量 -->
        <div class="guild-text">
          <!-- lp质押量 -->
           <span>LP{{common.pledgeAmount}}：</span>
            <div v-show="false">{{getTokenAmount()}}</div>
            <span>{{$gbUtils.formatNumber(lpMeb || 0)}}MEB / {{$gbUtils.formatNumber(lpUsdt || 0)}}USDT</span>
        </div>
      </div>
       <div class="community-list" @click="$gbUtils.handleToPage('/communitys')">
        <i class="iconfont icon-list"></i>
        <span>{{ community.communityList }}</span>
      </div>
    </div>
    <!-- 创始人 -->
    <div class="owner-box">
      <span>{{communityText.founder}}: </span>
      <span>{{ $gbUtils.formatAddress(communityInfo.address)}}</span>
      <div class="address-copy iconfont icon-copy" @click="handleToCopyAddress" data-clipboard-action="copy" :data-clipboard-text="communityInfo.address"></div>
    </div>
    <!-- 社区简介 -->
    <div class="dec-box">
      <div class="title">{{communityText.profile}}</div>
      <div class="dec">{{communityInfo.doc}}</div>
    </div>
     <!-- 社区补贴 -->
     <div class="btn-groups" v-if="communityInfo.memberType && !this.sourceType">
       <vanButton class="active-btn" @click="$gbUtils.handleToPage('/subsidized')">{{community.activityBtn}}</vanButton>
    </div>
    <!-- 按钮 -->
    <div class="btn-groups" v-if="sourceType">
      <vanButton class="active-btn" :loading="loadingJoin" @click="handleJoin" v-if="!hasJoin">{{communityText.join}}</vanButton>
      <vanButton class="cancel-btn" @click="handleCancel">{{communityText.cancel}}</vanButton>
    </div>
    <!-- 个性按钮 -->
    <div class="btn-groups" v-else-if="communityInfo.urlText">
       <vanButton class="active-btn" @click="handleToUrl">{{communityInfo.urlText}}</vanButton>
    </div>
  </div>
</template>

<script>
import { queryApplyJoin, queryCommunityInfo } from '../../../services/community'
import stakeLock from '@/utils/stakeLock' // 锁仓合约
import pancakeObj from '@/utils/pancakeRouter'
import Clipboard from 'clipboard'

export default {
  name: '',
  props: ['communityInfo', 'sourceType', 'nftLevel'],
  data () {
    return {
      loadingJoin: false,
      lpPrice: 0,
      mebPrice: 0,
      lpMeb: 0,
      lpUsdt: 0,
      hideCreateBtn: false,
      hasJoin: true
    }
  },
  computed: {
    common () {
      return this.$t('common')
    },
    dialogInfo () {
      return this.$t('community.dialog')
    },
    tips () {
      return this.$t('community.tips')
    },
    communityText () {
      return this.$t('community.communityInfo')
    },
    community () {
      return this.$t('community')
    }
  },
  methods: {
    // 复制合约地址
    handleToCopyAddress () {
      const clipboard = new Clipboard('.address-copy')
      console.log(clipboard)
      clipboard.on('success', () => {
        return this.$toast('success')
      })
      clipboard.on('error', (error) => {
        console.log('error', error)
      })
    },
    // 个性按钮跳转链接
    handleToUrl () {
      if (!this.communityInfo.url) return
      window.location.href = this.communityInfo.url
    },
    // 用户申请加入社区
    async handleJoin () {
      if (!this.nftLevel) return this.$toast(this.tips.nftRequire)
      this.$dialog.confirm({
        title: this.dialogInfo.tips,
        message: this.dialogInfo.joinTitle,
        className: 'message-title',
        confirmButtonColor: '#A85FFC',
        confirmButtonText: this.common.confirm,
        cancelButtonText: this.common.cancel
      }).then(() => {
        this.join()
      })
    },
    // 加入社区
    async join () {
      this.lodingJoin = true
      const resp = await queryApplyJoin(this.communityInfo.id)
      this.lodingJoin = false
      if (!resp.success) return
      this.handleCancel()
    },
    handleCancel () {
      this.$router.go(-1)
      // this.$gbUtils.handleToPage('/community')
    },
    getTokenAmount () {
      const params = this.$gbUtils.getUsdtMebAmount(
        this.communityInfo.mebUsdtLp,
        this.lpPrice,
        this.mebPrice
      )
      console.log(params)
      this.lpMeb = params.meb
      this.lpUsdt = params.usdt
    },
    // 获取lp价值
    async getLpPrice () {
      const lpPriceResp = await stakeLock.getLpPrice(0)
      if (lpPriceResp.success) {
        this.lpPrice = this.$gbUtils.formatBalance(lpPriceResp.result)
      }
      console.log('lpPrice:', this.lpPrice)
    },
    // 获取meb的单价
    async getMebPrice () {
      const resp = await pancakeObj.getAmountsIn(1)
      if (!resp.success) return
      this.mebPrice = resp.result
      console.log('mebPrice:', this.mebPrice)
    },
    // 获取用户是否已经加入社区
    async getCommunityInfo () {
      const resp = await queryCommunityInfo()
      if (!resp.success) return
      this.hasJoin = resp.result && resp.result.id
    },
    async init () {
      await this.getLpPrice()
      await this.getMebPrice()
      this.getCommunityInfo()
      this.getTokenAmount()
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style scoped lang="scss">
.community-wrapper {
  min-height: 80%;
  width: 100%;
  min-height: 570px;
  background: rgba(255, 255, 255, 0.39);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px 16px 30px 16px;
  box-sizing: border-box;
  .wrapper-header {
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #e3e3e3;
    color: #818086;
    position: relative;
    .logo-box {
      width: 60px;
      height: 60px;
      background: rgba(255, 255, 255, 0.39);
      border: 1px solid #707070;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .guild-info {
      padding-left: 10px;
      width: calc(100% - 60px);
      box-sizing: border-box;
      .guild-name {
        line-height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        .name {
          font-size: 24px;
          color: #16172a;
        }
        .set-box {
          display: flex;
          align-content: center;
          color: #2A2B33;
          font-weight: bold;
          cursor: pointer;
          .iconfont {
            font-size: 14px;
            color: #A2A2A2;
            font-weight: normal;
            margin-right: 4px;
          }
        }
      }
      .guild-text {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        color: #818086;
        margin-bottom: 3px;
        .mebmber-item {
          margin-left: 15px;
        }
        .lp-item {
          margin-right: 10px;
        }
      }
    }
    .community-list {
      position: absolute;
      right: 0;
      top: 0;
      color: #2A2B33;
      font-weight: bold;
      .iconfont {
        font-size: 12px;
        margin-right: 5px;
        color: #A2A2A2;
        font-weight: normal;
      }
    }
  }
  .owner-box {
    padding: 10px 0;
    border-bottom: 1px solid #E3E3E3;
    font-size: 15px;
    color: #818086;
    display: flex;
    align-items: center;
    :first-child {
      color: #A85FFC;
    }
    .address-copy {
      color: #A85FFC;
      margin-left: 5px;
      font-size: 22px;
    }
  }
  .dec-box {
    min-height: 300px;
    padding: 20px 0;
    .title {
      font-size: 15px;
      margin-bottom: 10px;
    }
    .dec {
      line-height: 1.5em;
    }
  }
  .btn-groups {
    button {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      font-size: 17px;
      font-weight: bold;
      color: #fff;
      margin-bottom: 10px;
    }
    .active-btn {
      background: #A85FFC;
    }
    .cancel-btn {
      margin-top: 10px;
      background: #C1C1C1;
    }
  }
}
</style>

<style lang="scss">
.message-title {
 .van-dialog__message--has-title {
    color: #A85FFC;
    font-size: 24px;
    line-height: 1.3em;
    letter-spacing: 1px;
  }
}
</style>
