import request from '@/utils/request'

// 创建钱包
export const queryCreateWallet = (params) => {
  return request('POST', '/app/wallet/create_wallet', params, false)
}
// 货币详情 币种 1-GEP 2-BNB 3-USDT
export const queryCurrencyDetail = (params) => {
  return request('GET', '/app/currency/currency_details', params)
}
// 查询剩
export const queryBalance = (params) => {
  return request('POST', '/app/pay/view_balance', params)
}
// 更换钱包名
export const queryWalletUpdateName = (params) => {
  return request('POST', '/app/wallet/update_name', params)
}
// 钱包信息
export const queryWalletInfo = (params) => {
  return request('GET', '/app/wallet/info', params, true, true)
}
// 我的钱包信息
export const queryMyWallet = (params) => {
  return request('GET', '/app/wallet/my_wallet', params)
}
// 更换钱包信息
export const queryChangeAvator = (params) => {
  return request('POST', '/app/wallet/update_icon', params)
}
// 获取原力值
export const queryEstimateForceValue = (params) => {
  return request('POST', '/app/force_value/estimate_force_value', params)
}
