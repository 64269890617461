import Web3 from 'web3'
import store from '@/store'
import config from '@/config/app.config.js'
const contractsInfo = config.contractsInfo
const provider = 'https://bsc-dataseed1.ninicoin.io'
// const providerTest = 'https://data-seed-prebsc-1-s1.binance.org:8545'

var web3 = new Web3()
try {
  web3 = new Web3(window.web3.currentProvider)
} catch {
  web3 = new Web3(new Web3.providers.HttpProvider(provider))
  // web3 = new Web3(new Web3.providers.HttpProvider(providerTest))
}

const currentAccount = store.state.myAcount // 当前钱包账户
// pancake合约对象
const contractRouter = new web3.eth.Contract(
  contractsInfo.routerContract.abi,
  contractsInfo.routerContract.address
)
// 统一错误返回
const failResult = (message) => ({ success: false, message })
// 统一成功返回
const successResult = (result) => ({ success: true, result })

/**
 *   allowance, 授权dex使用币的额度
 * @tokenObj 合约币种对象
 * @return 额度
 */
const allowance = async (tokenObj) => {
  const accounts = await web3.eth.getAccounts()
  const myContract = await new web3.eth.Contract(tokenObj.abi, tokenObj.address)
  try {
    var resp = await myContract.methods
      .allowance(accounts[0], contractsInfo.routerContract.address)
      .call({
        from: accounts[0]
      })
    console.log(resp)
    resp = web3.utils.fromWei(resp, 'ether')
    return successResult(resp)
  } catch (error) {
    console.log(error)
    return failResult(error)
  }
}

/**
 * approveContract 授权dex使用币给pancake
 * @tokenObj dex币的合约对象
 * @param inputValue 输入的数量
 */

const approveContract = async (tokenObj, inputValue) => {
  const stringValue = web3.utils.toWei(inputValue.toString(), 'ether')
  const accounts = await web3.eth.getAccounts()
  const tokenContractObj = await new web3.eth.Contract(
    tokenObj.abi,
    tokenObj.address
  )
  try {
    const resp = await tokenContractObj.methods
      .approve(contractsInfo.routerContract.address, stringValue)
      .send({
        from: accounts[0]
      })
    return successResult(resp)
  } catch (error) {
    console.log(error)
    return failResult(error)
  }
}

/**
 * 通过两个币的合约地址获取组lp对应数量
 * @address addressA
 * @address addressB
 * @path [addressA, addressB]
 * @uint amountIn,
 * @uint amountBMin,
 * @address to 当前地址
 * @uint deadline 默认当前时间戳delay 20 分钟
 * @return (uint amountA, uint amountB)
 */
const getAmountsOut = async (amountIn) => {
  amountIn = web3.utils.toWei(amountIn.toString(), 'ether')
  const path = [
    contractsInfo.tokenUSDTContract.address,
    contractsInfo.tokenGEPContract.address
  ]
  try {
    const resp = await contractRouter.methods
      .getAmountsOut(amountIn, path)
      .call()
    // 返回map [0: usdt, 1:meb]
    var amountOut = web3.utils.fromWei(resp[1], 'ether')
    // pancake添加流动性的时候收取千分之零点二点五的手续费
    amountOut = amountOut * (1 + 0.0025)
    return successResult(amountOut)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * 通过两个币的合约地址获取组lp对应数量
 * @address addressA
 * @address addressB
 * @path [addressA, addressB]
 * @uint amountIn,
 * @uint amountBMin,
 * @address to 当前地址
 * @uint deadline 默认当前时间戳delay 20 分钟
 * @return (uint amountA, uint amountB)
 */
const getAmountsIn = async (amountOut) => {
  amountOut = web3.utils.toWei(amountOut.toString(), 'ether')
  const path = [
    contractsInfo.tokenUSDTContract.address,
    contractsInfo.tokenGEPContract.address
  ]
  try {
    const resp = await contractRouter.methods
      .getAmountsIn(amountOut, path)
      .call()
    // 返回map [0: usdt, 1:meb]
    const amount = web3.utils.fromWei(resp[0], 'ether')
    return successResult(amount)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * 添加流动性
 * @tokenA 币A的address
 * @tokenB 币B的address
 * @amountADesired 是用户期望的数量（输入值）
 * @amountBDesired 是用户期望的数量（输入值）
 * @amountAMin
 * @amountBMin 是用户期望值减去滑点容忍度后的值
 * @address 账户地址
 * @deadline 默认当前时间戳delay 20 分钟
 * @skid 滑点容差(百分值) 小于1可能会失败 大于5可能会超前交易 大于50无效
 * @return (uint amountA, uint amountB, uint liquidity)
 */
const addLiquidity = async (amountADesired, amountBDesired, skid) => {
  if (!skid) skid = 2
  const tokenA = contractsInfo.tokenUSDTContract.address
  const tokenB = contractsInfo.tokenGEPContract.address
  // console.log('tokenA:', tokenA)
  // console.log('tokenB:', tokenB)
  const block = await web3.eth.getBlock('latest')
  const deadline = (block.timestamp + 300).toString()
  var amountAMin = (amountADesired * (100 - skid)) / 100
  var amountBMin = (amountBDesired * (100 - skid)) / 100
  amountADesired = web3.utils.toWei(amountADesired.toString(), 'ether')
  amountBDesired = web3.utils.toWei(amountBDesired.toString(), 'ether')
  amountAMin = web3.utils.toWei(amountAMin.toString(), 'ether')
  amountBMin = web3.utils.toWei(amountBMin.toString(), 'ether')
  console.log(
    `amountADesired: ${amountADesired} amounBDesired:${amountBDesired} amountAMin: ${amountAMin} amountBMin：${amountBMin}`
  )
  try {
    // addLiquidityETH
    console.log(web3.utils.toWei(amountADesired.toString(), 'ether'))
    const resp = await contractRouter.methods
      .addLiquidity(
        tokenA,
        tokenB,
        amountADesired,
        amountBDesired,
        amountAMin,
        amountBMin,
        currentAccount,
        deadline
      )
      .send({
        from: currentAccount
      })
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * 移除流动性
 * @address tokenA
 * @address tokenB
 * @uint liquidity 抽取的流动性的量
 * @uint amountAMin,
 * @uint amountBMin,
 * @address to 当前地址
 * @uint deadline 默认当前时间戳delay 20 分钟
 * @return (uint amountA, uint amountB)
 */
const removeLiquidity = async (liquidity) => {
  const tokenA = contractsInfo.tokenUSDTContract.address
  const tokenB = contractsInfo.tokenGEPContract.address
  const block = await web3.eth.getBlock('latest')
  const deadline = (block.timestamp + 300).toString()
  var amountAMin = web3.utils.toWei('0', 'ether')
  var amountBMin = web3.utils.toWei('0', 'ether')
  liquidity = web3.utils.toWei(liquidity.toString(), 'ether')
  console.log('yu' + liquidity)
  try {
    const resp = await contractRouter.methods
      .removeLiquidity(
        tokenA,
        tokenB,
        liquidity,
        amountAMin,
        amountBMin,
        currentAccount,
        deadline
      )
      .send({
        from: currentAccount,
        gasLimit: '300000'
      })
    return successResult(resp)
  } catch (err) {
    console.error(err)
    return failResult(err)
  }
}

/**
 * quote 查询 获得交易对的兑换价格
 * @uint256 amountA
 * @uint256 reserveA
 * @uint256 reserveB
 * @return uint256 reserveB
 */
const quote = async (amountA, reserveA, reserveB) => {
  amountA = web3.utils.toWei(amountA.toString(), 'ether')
  reserveA = web3.utils.toWei(reserveA.toString(), 'ether')
  reserveB = web3.utils.toWei(reserveB.toString(), 'ether')

  try {
    var resp = await contractRouter.methods
      .quote(amountA, reserveA, reserveB)
      .call({ from: currentAccount })
    resp = web3.utils.fromWei(resp, 'ether')
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}
const pancakeObj = {
  allowance, // 获取dex授权给pancake的额度
  approveContract, // 获取授权币给pancake
  getAmountsIn, // 获取 n个meb所对应的usdt的值
  getAmountsOut, // 获取 n个usdt所对应meb的值 增加lp的时候输出得到的数据
  addLiquidity, // 添加流动性
  removeLiquidity, // 移除流动性
  quote // 计算比例
}

export default pancakeObj
